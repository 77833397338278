import type { Performer, Status as PerformerStatus, Service, ServiceStatus, SimplePerformer } from '@/ontology/performer';
import { defineStore } from 'pinia';
import notifications from '@/socket';
import same from 'deep-equal';
import config from '@/config';
import { serviceStatus, statuses, type Filter, defaultServices, lastSeenAgo } from '@/api/performer/utils';
import { getListPerformers, getByAdvert, getPeekers, getTeasers, getById, getRecommended, listUsernames } from '@/api/performer';
import { addFavorite, getFavorites, removeFavorite } from '@/api/client/client_accounts.favorites';
import type { Paged, Response } from '@/api/response';
import { useUserStore } from './user';
import { useAlertsStore } from './alerts';
import { minutes, seconds } from '@/utils';
import i18n from '@/translations';
import { getUnixTime, quartersInYear } from 'date-fns';
import { useRoute } from 'vue-router';
import router from './../router';
import { addBlock, unBlock } from '@/api/client/client_accounts.blocks';

interface State {
    performers: { [id: number]: Performer };
    performerNames: SimplePerformer[] | undefined;
    slices: { [name: string]: Slice };
    currentSlice: 'grid' | 'favorites' | 'voyeur' | 'peek' | 'recommended' | undefined;
}

// a slice performers is a specific list of performers.
// It is a filtered subset of performers, sorted in a specific way
// In thuis.nl for example, there's the list for the grid, for related performers
// to the current performer, performers eligable for teasing or meekijking
export interface Slice {
    name: string;
    filter: Filter;
    items: number[];
    total?: number;
    //timestamp indicating the age of this slice
    fetchedAt: number;
    status: 'new' | 'fetching' | 'fetched' | 'error';
}

interface ServiceUpdate {
    performerId: number;
    serviceName: Service;
    serviceStatus: boolean;
    status?: PerformerStatus;
    countries?: string;
    services?: { [key: string]: boolean };
}

type Loader = (filter: Filter) => Promise<Response<Paged<Performer>>>;

export const usePerformerStore = defineStore('performer', {
    state: (): State => ({
        performers: {},
        performerNames: undefined,
        slices: defaultSlices(['grid', 'favorites', 'voyeur', 'peek', 'cammers', 'recommended']),
        currentSlice: undefined
    }),
    actions: {
        initialize() {
            notifications.subscribe('status', (update: ServiceUpdate) => {
                this.updateServices(update);
            });

            notifications.subscribe('service', (update: ServiceUpdate) => {
                this.updateServices(update);
            });
            notifications.subscribe('authentication', (update: { type: 'loggedin' | 'loggedout' }) => {
                switch (update.type) {
                    case 'loggedin':
                        this.onlogin();
                        break;
                    case 'loggedout':
                        this.onlogout();
                        break;
                    default:
                        throw new Error(`That's a surprising authentication type: ${update.type}`);
                }
            });
        },

        updateServices(update: ServiceUpdate) {
            let performer = this.performers[update.performerId];

            if (!performer) {
                if (this.shouldLoad(update)) {
                    //if the update is about a performer that becomes available
                    //for the 'current' slice and not known yet, load her!
                    //initialize with a very unavailable status and services to prevent
                    //loading performers often, because of multiple status updates in a row
                    performer = this.performers[update.performerId] = {
                        id: update.performerId,
                        status: 'OFFLINE',
                        services: defaultServices()
                    } as Performer;
                    this.loadPerformerById(update.performerId);
                }
                return;
            }

            if (update.status) {
                performer.status = update.status;
            }

            if (update.services) {
                for (let service in update.services) {
                    if (performer.services[service] !== undefined) {
                        performer.services[service]!.intention = update.services[service];
                    }
                }
            }

            if (update.serviceName) {
                if (performer.services[update.serviceName] !== undefined) {
                    performer.services[update.serviceName].intention = update.serviceStatus;
                }
            }

            //redo all service statuses for this performer..
            const old = statuses(performer.services);
            for (let service in performer.services) {
                performer.services[service].status = serviceStatus(performer.status, service as Service, performer.services[service].intention);
            }
            const current = statuses(performer.services);

            if (becameAvailable(current.cam, old.cam)) {
                notifications.sendLocalEvent('performer_available', { performer: performer.id });

                if (this.isFavorite(performer.id) && lastSeenAgo(performer) > minutes(60)) {
                    performer.statusUpdated = getUnixTime(new Date());
                    useAlertsStore().performerMessage({ advert: performer.advertNumber, message: i18n.global.t('account.alerts.favoriteOnline', { performer: performer.nickname }), avatar: performer });
                }
            }

            //now check if a performer maybe became available for some slices\
            this.updateSlices(performer.id, old, current);
        },
        // an update comes in about an unknown performer so far.
        // Did she become available for a hot slice?
        shouldLoad(update: ServiceUpdate) {
            const hotServices: any[] = ['peek', 'voyeur'];

            if (!hotServices.includes(this.currentSlice)) {
                return false;
            }

            if (update.services !== undefined) {
                return update.services[this.currentSlice!];
            }

            if (update.serviceName) {
                return update.serviceName == this.currentSlice && update.serviceStatus;
            }
            return false;
        },
        async loadPerformer(advertNumber: number) {
            const { error, result } = await getByAdvert(advertNumber);
            if (error || !result) {
                router.replace({ name: '404' }); // Replace to prevent history entry
                return;
            }

            this.setPerformer(result);

            return result;
        },
        async listUsernames() {
            if (this.performerNames) {
                return;
            }

            const { error, result } = await listUsernames();
            if (error) {
                return;
            }

            if (!result) {
                throw new Error('Imposible!');
            }

            this.performerNames = result;
        },
        async setPerformer(value: Performer) {
            const known = this.performers[value.id];

            if (known) {
                // do not update the cached preformer with the freshly loaded one
                // if the freshly loaded doesn't contain photos, but the cached one does
                if (!(!value.photos && known.photos)) {
                    this.performers[value.id] = value;
                }

                this.updateSlices(value.id, statuses(known.services), statuses(value.services));
            } else {
                this.performers[value.id] = value;
            }
        },
        async loadPerformerById(id: number) {
            const { error, result } = await getById(id);
            if (error) {
                //TODO: handle that error
            }
            if (!result) {
                throw new Error('Impossible');
            }

            this.setPerformer(result);

            return result;
        },

        async loadSlice(name: string, filter: Filter, loader: Loader = getListPerformers) {
            const slice = this.getSlice(name);

            if (same(filter, slice.filter) && age(slice.fetchedAt) < config.GridCache) {
                return slice;
            }

            slice.status = 'fetching';
            slice.filter = filter;

            try {
                const { error, result } = await loader(filter);

                if (error) {
                    //TODO: handle this error. Should not happen off course
                    slice.status = 'error';
                    return slice;
                }

                if (!result) throw new Error('Impossible');

                // Batch state updates
                this.$patch(() => {
                    slice.status = 'fetched';
                    slice.fetchedAt = Date.now();
                    slice.total = result.total;

                    // Add all fetched performers to this.performers
                    result.items.forEach(p => this.setPerformer(p));

                    slice.items = result.items.map(p => p.id);

                    if (['voyeur', 'peek'].includes(name)) {
                        slice.items.sort(this.favoriteSorted);
                    }
                });

                return slice;
            } catch (e) {
                slice.status = 'error';
                throw e;
            }
        },

        //TODO: custom sort order so favorites that come online will move up
        async loadFavorites(filter: Filter = { offset: 0, limit: 40 }) {
            const userStore = useUserStore();
            if (!userStore.account) {
                throw new Error('No accounts have no favorites..');
            }

            const id = userStore.account.id!;
            if (!id) {
                return false;
            }

            return await this.loadSlice('favorites', filter, (query: Filter) => getFavorites(id, query));
        },

        async loadRecommended(filter: Filter = { offset: 0, limit: 42, search: '' }, relatedTo: number) {
            return await this.loadSlice('recommended', filter, (query: Filter) => getRecommended(query, relatedTo));
        },

        async loadPeekers(filter: Filter = { offset: 0, limit: 100, search: '' }, relatedTo?: number) {
            return await this.loadSlice('peek', filter, (query: Filter) => getPeekers(query, relatedTo));
        },

        async loadTeasers(filter: Filter = { offset: 0, limit: 100, search: '' }, relatedTo?: number) {
            return await this.loadSlice('voyeur', filter, (query: Filter) => getTeasers(query, relatedTo));
        },

        async toggleFavorite(id: number) {
            const account = useUserStore().account;
            if (!account) {
                //TODO: handle this bloody error:
                throw new Error('No accounts have no favorites..');
            }

            const favorites = this.getSlice('favorites');
            let ix = favorites.items.indexOf(id);
            if (ix < 0) {
                const { error, result } = await addFavorite(id, account.id!);
                if (result && !error) {
                    favorites.items.unshift(id);
                }

                const performer = this.performers[id];
                performer.isFavourite = true;

                ['peek', 'voyeur'].forEach(service => {
                    if (performer.services[service].status != 'available') {
                        return;
                    }

                    this.getSlice(service).items = this.getSlice(service).items.sort(this.favoriteSorted);
                });
            } else {
                //remove the favorite
                const { error, result } = await removeFavorite(id, account.id!);
                if (result && !error) {
                    favorites.items.splice(ix, 1);
                }
                this.getById(id).isFavourite = false;
            }
        },

        async toggleBlock(id: number) {
            const account = useUserStore().account;
            if (!account) {
                throw new Error('No accounts have no unsubscriptions..');
            }

            const performer = this.getById(id);
            const oldValue = performer.isBlocked;
            performer.isBlocked = !performer.isBlocked;
            try {
                if (oldValue) {
                    await unBlock(account.id!, id);
                } else {
                    await addBlock(account.id!, id);
                }
            } catch (error) {
                performer.isBlocked = oldValue;
            }
        },

        getSlice(name: string) {
            if (!(name in this.slices)) {
                throw new Error(`Unknown slice ${name}. Available: ${Object.keys(this.slices).join(',')} `);
            }
            return this.slices[name];
        },

        //there are slices that are filtered on the availability of a service.
        updateSlices(performer: number, old: { [service: string]: ServiceStatus }, current: { [service: string]: ServiceStatus }) {
            ['peek', 'voyeur'].forEach(service => {
                const slice = this.getSlice(service);
                if (slice.status != 'fetched') {
                    return;
                }

                //if the service status hasn't changed, whether the performer is
                //part of the  slice does not change
                if (old[service] == current[service]) {
                    return;
                }

                switch ('available') {
                    //the service was available, not anymore..
                    case old[service]: {
                        const ix = slice.items.indexOf(performer);
                        if (ix > -1) {
                            slice.items.splice(ix, 1);
                        }
                        break;
                    }
                    //the service became available
                    case current[service]: {
                        slice.items = slice.items.concat(performer).sort(this.favoriteSorted);
                    }
                }
            });
        },
        favoriteSorted(a: number, b: number) {
            const aFav = this.performers[a].isFavourite;
            const bFav = this.performers[b].isFavourite;

            if (aFav && !bFav) return -1;
            if (!aFav && bFav) return 1;

            return 0;
        },
        resetFetched(name: string) {
            const slice = this.getSlice(name);
            slice.fetchedAt = 0;
        },
        getById(id: number) {
            return this.performers[id];
        },
        getByAdvert(id: number) {
            return Object.values(this.performers).find(({ advertNumber }) => advertNumber == id);
        },
        getVoyeurs(){
            return this.getSlice("voyeur").items;
        },
        isFavorite(id: number) {
            return this.getById(id).isFavourite;
            //return this.slices.favorites.items.indexOf(id) > -1;
        },
        toggleSubscribed(id: number) {
            const p = this.performers[id];
            if (!p) {
                return;
            }
            p.isSubscribed = !p.isSubscribed;
        },
        isSubscribed(id: number) {
            const p = this.performers[id];
            if (!p) {
                return;
            }
            return p.isSubscribed;
        },
        async onlogin() {
            await router.isReady();
            const query: any = router.currentRoute.value.query.hasOwnProperty('limit') ? router.currentRoute.value.query : { page: 1, offset: 0, limit: 40 };
            this.loadFavorites(query);
        },
        onlogout() {
            this.slices.favorites = {
                name: 'favorites',
                filter: { offset: 0, limit: -1 },
                status: 'new',
                items: [],
                fetchedAt: Date.now()
            };
        }
    },

    getters: {
        isFavoriteNotWorkingButWhy(state) {
            const hashed = state.slices.favorites.items.reduce(numberHash, {});
            return (id: number) => {
                return id in hashed;
            };
        }
    }
});

//returns how long ago a moment (in js timestamp) was, in seconds
function age(moment: number): number {
    return (Date.now() - moment) / 1000;
}

function defaultSlices(names: string[]) {
    let result: { [name: string]: Slice } = {};
    for (let name of names) {
        result[name] = {
            name,
            filter: { offset: 0, limit: -1 },
            status: 'new',
            items: [],
            fetchedAt: Date.now()
        };
    }
    return result;
}

function numberHash(sofar: { [key: number]: boolean }, current: number) {
    if (!sofar) {
        sofar = {};
    }

    sofar[current] = true;
    return sofar;
}

function becameAvailable(now: ServiceStatus, old: ServiceStatus) {
    return now == 'available' && now != old;
}
